import { GridContainer } from '@/components/grid-container';
import { Text } from '@/components/text';
import { urlForImage } from '@/lib/sanity/sanity';
import { item } from '@/lib/transitions';
import { styled } from '@/stitches.config';
import { motion } from 'framer-motion';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { isPresent } from 'ts-is-present';
import { FullProject } from './types';
import { useEffectOnce } from 'react-use';
import {
  defaultCurve,
  defaultDelay,
  defaultDuration,
  defaultYOffset,
} from '../enter-animaton/config';

type ProjectCoverProps = {
  projectCoverImage?: FullProject['cover'];
  projectCoverVideo?: FullProject['coverVideo'];
  projectDescription: string;
  projectTransitionColor?: FullProject['color'];
};

type ProjectWrapperProps = {
  children?: React.ReactNode;
  description: string;
  color?: string;
};

const LAYOUT_TRANSITION_TIME = 0.8;

const ProjectCoverWrapper = React.forwardRef(
  (
    props: ProjectWrapperProps,
    ref: React.ForwardedRef<HTMLDivElement> | null
  ) => {
    const { children, description, color = '#999' } = props;

    const router = useRouter();

    //@TODO CLEAN THIS UP
    if (!router) {
      return null;
    }
    if (!router.query) {
      return null;
    }
    if (!router.query.slug) {
      return null;
    }

    const hasSlug = router.query.slug[0];
    if (!hasSlug) {
      return null;
    }

    return (
      <Wrapper ref={ref} css={{ backgroundColor: color }}>
        {children}
        <motion.div variants={item}>
          <GridContainer
            css={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              '@bp6': {
                paddingRight: '15vw',
              },
            }}
          >
            <Text
              as={motion.h1}
              size={{
                '@initial': 8,
                '@bp3': 11,
                '@bp5': 14,
              }}
              initial={{ opacity: 0, y: defaultYOffset }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: defaultDuration,
                delay: defaultDelay + 0.2,
                ease: defaultCurve,
              }}
              css={{
                display: 'block',
                zIndex: 1,
                color: 'white',
                '@bp3': {
                  gridColumn: 'wide',
                },
                // Targets desktops with a display configuration of 150% that have aprx 947px of view height
                // To ensure long case study titles are not too big and doesn't bleed into the header
                '@media only screen and (max-height: 940px)': {
                  '@bp5': {
                    fontSize: '$10',
                  },
                },
              }}
            >
              {description}
            </Text>
          </GridContainer>
        </motion.div>
      </Wrapper>
    );
  }
);
ProjectCoverWrapper.displayName = 'ProjectCoverWrapper';

export const ProjectCover = React.forwardRef(
  (props: ProjectCoverProps, ref: React.Ref<HTMLDivElement> | undefined) => {
    const {
      projectCoverImage,
      projectDescription,
      projectCoverVideo,
      projectTransitionColor,
    } = props;

    /**
     * Ok. Here goes. What I actually wanted to do was give the animation with
     * a layoutId somethink like variants so I can orchestrate it using framer-motion
     * https://www.framer.com/docs/animation/##orchestration
     * However, I don't see a way to do this. Seems like motion.div components that use
     * a layoutId are somehow different than normal motion.divs that can use variants.
     *
     * I might be missing something here. Oh well.
     *
     * The way I solved the video component being loaded while the animation is not done yet,
     * is by simply hardcoding an extra render after the time it takes to animate the box.
     * So if the box transitions in 1 second, we wait 1 second before we trigger a new state
     * in React. delayHasPassed will be true and voila, you'll see your coverImage/coverVideo!
     *
     * Yes. This needs to be cleaned up. But we also need to release the website.
     *
     *
     */
    const [delayHasPassed, setDelayHasPassed] = useState(false);

    useEffectOnce(() => {
      const timer = setTimeout(() => {
        setDelayHasPassed(true);
      }, LAYOUT_TRANSITION_TIME * 1000);

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    });

    if (isPresent(projectCoverImage)) {
      const imageUrl = urlForImage(projectCoverImage.asset).url();

      if (imageUrl) {
        return (
          <ProjectCoverWrapper
            description={projectDescription}
            ref={ref}
            color={
              projectTransitionColor
                ? projectTransitionColor.hex
                : props.projectCoverImage?.asset.metadata.palette.vibrant
                    .background
            }
          >
            {delayHasPassed && (
              <>
                {projectCoverVideo ? (
                  <motion.div
                    inherit={false}
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{
                      duration: 1,
                    }}
                  >
                    <>
                      <CoverOverlay
                        css={{
                          zIndex: 1,
                        }}
                      />
                      <CoverVideo
                        src={projectCoverVideo.url}
                        controls={false}
                        autoPlay
                        loop
                        playsInline
                        muted
                      />
                    </>
                  </motion.div>
                ) : (
                  <motion.div
                    inherit={false}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                    }}
                  >
                    <CoverOverlay />
                    <CoverImage
                      src={imageUrl}
                      layout="fill"
                      placeholder="blur"
                      blurDataURL={projectCoverImage.asset.metadata.lqip}
                    />
                  </motion.div>
                )}
              </>
            )}
          </ProjectCoverWrapper>
        );
      }
    }

    return <ProjectCoverWrapper description={projectDescription} ref={ref} />;
  }
);
ProjectCover.displayName = 'ProjectCover';

const Wrapper = styled('div', {
  height: '80vh',
  // overflow: 'hidden',
  position: 'relative',

  '&::after': {
    content: '',
    position: 'absolute',
    zIndex: '$default',
    top: 0,
    left: 0,
    right: 0,
    height: '$10',
  },
});

const CoverImage = styled(NextImage, {
  objectFit: 'cover',
  position: 'absolute',
  inset: 0,
  // zIndex: 1,
});

const CoverVideo = styled('video', {
  objectFit: 'cover',
  position: 'absolute',
  height: '100%',
  width: '100%',
  // zIndex: 1,
});

const CoverOverlay = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.2)',
  zIndex: 1,
});
