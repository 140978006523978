export const page = {
  visible: {
    y: 0,
    transition: {
      duration: 1,
      when: 'beforeChildren',
      staggerChildren: 0.1,
      ease: 'easeOut',
    },
  },
  hidden: {
    y: '100px',
  },
  exit: {
    y: '-100px',
    transition: {
      duration: 1,
      ease: 'easeIn',
    },
  },
};

export const item = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: { opacity: 0, y: 20 },
};
