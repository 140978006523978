import { CapsizedText as Text } from '@/components/text';
import { urlForImage } from '@/lib/sanity/sanity';
import { styled } from '@/stitches.config';
import Image from 'next/image';
import Link from 'next/link';
import type { NextProject } from './types';

type Props = {
  project: NextProject;
};

export function LinkNextProject({ project }: Props) {
  const { cover, title, slug } = project;

  const coverUrl = urlForImage(cover.asset).url();

  if (!coverUrl) {
    return null;
  }

  return (
    <Wrapper>
      <Image
        alt={`Image for next project, ${title}`}
        src={coverUrl}
        layout="fill"
        objectFit="cover"
        placeholder="blur"
        blurDataURL={cover.asset.metadata.lqip}
      />
      <Overlay />

      <Link href={`/project/${slug.current}`} passHref>
        <a>
          <Text
            as="div"
            size={3}
            css={{ marginBottom: '$6', '@bp3': { marginBottom: '$7' } }}
          >
            Next case
          </Text>
          <Text
            as="h3"
            size={4}
            css={{
              '@bp1': {
                capSize: 5,
              },
              '@bp2': {
                capSize: 6,
              },
              '@bp3': { capSize: 8, marginLeft: '-0.1ch' },
            }}
          >
            {title}
          </Text>
        </a>
      </Link>
    </Wrapper>
  );
}

const Overlay = styled('div', {
  backgroundColor: 'black',
  height: '100%',
  opacity: 0.2,
  position: 'absolute',
  transition: 'opacity 1s',
  top: 0,
  width: '100%',
});

const Wrapper = styled('div', {
  marginTop: '$4',
  aspectRatio: '2 / 1',
  position: 'relative',
  backgroundColor: 'black',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  width: '100%',

  '@supports not (aspect-ratio: 2 / 1)': {
    paddingBottom: `${100 / (2 / 1)}%`,
  },

  '@bp2': {
    aspectRatio: '8 / 3',

    '@supports not (aspect-ratio: 8 / 3)': {
      paddingBottom: `${100 / (8 / 3)}%`,
    },
  },

  h3: {
    transition: 'transform 0.3s ease-out',
  },

  img: {
    transition: 'transform 0.8s ease-out',
  },

  '&:hover': {
    [`${Overlay}`]: {
      opacity: '0.4',
    },

    img: {
      transform: 'scale(1.1)',
    },

    h3: {
      transform: 'translateX(5px)',
    },
  },

  '> a': {
    border: 0,
    color: 'white',
    fontSize: '$2',
    padding: '$4',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    textDecoration: 'none',
    '@bp2': {
      fontSize: '$3',
    },
    '@bp3': {
      padding: '$6',
    },
    '@bp4': {
      padding: '$8',
    },
  },
});
